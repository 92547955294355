import "./resources-tab.css"

import React from "react"

import axios from "axios"

import { API_BASE_URL } from "../../constants/config"
import { defaultDog } from "../../constants/defaults"
import {
  format_url,
  format_website,
} from "../../constants/utility"

const Tab1 = () => {
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/website/resources/vets").then(({ data }) => {
                setData(data.data.data);
            })
        }
        return () => {
            m = false;
        }
    }, [])
    return <div className="resources-tab resources-tab-1">
        <div className="content">
            <h3>Meet Our Veterinarians</h3>
            <p className="intro">Our partner veterinarians work tirelessly to make sure all of our Labs are healthy and ready to be adopted. Thanks to all of our partner-veterinarians for their support and discounts. We couldn’t rescue Labs without their help!</p>
            <div className="items">
                {data && data.map((item, key) => {
                    return <div key={key} className="item brown">
                        <div className="thumb">
                           <a href={item.website?format_url(item.website):"javascript:;"} target="_blank"><img src={item.photo ? API_BASE_URL.replace("/v1", "/") + item.photo.path : defaultDog} alt={item.title} /></a>
                        </div>
                        <div className="text">
                            <h4>{item.clinic_name}</h4>
                            <p>{item.address} {item.city} {item.state} {item.zipcode}<br />
                                Phone: {item.office_phone}</p>
                            <p>{item.office_hours}</p>
                            {item.website && String(item.website).trim() !== "" && <p><a className="website" href={format_url(item.website)} target="_blank">{format_website(item.website)}</a></p>}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}
const Tab2 = () => {
    const [data, setData] = React.useState(null);

    React.useEffect(() => {

        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/website/resources/links").then(({ data }) => {
                setData(data.data.data);
            })
        }
        return () => {
            m = false;
        }
    }, [])
    return <div className="resources-tab resources-tab-2">
        <div className="content">
            <h3>Links to Dog-Related Fun Stuff</h3>
            <div className="items">
                {data && data.map((item, key) => {
                    return <div className="item" key={key}>
                        <div className="thumb">
                            <span>{item.title}</span>
                            <img src={item.featured_image ? API_BASE_URL.replace("/v1", "/") + item.featured_image.path : defaultDog} alt={item.title} />
                        </div>
                        <div className="text site-content" dangerouslySetInnerHTML={{ __html: item.content }} />

                    </div>
                })}
            </div>
        </div>
    </div>
}
const ResourcesTab = {
    Tab1,
    Tab2
}

export default ResourcesTab